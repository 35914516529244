<template>
  <section class="TopHeader flex items-center">
    <HeaderCustomerService @open-mobile-menu="showMobileNavigationMenu">
      <CurrencySelectionPopup
        v-if="displayCurrencySelectionPopup"
        @close-popup="displayCurrencySelectionPopup = false"
        @open-closest-showroom="showClosestShowroom()"
      />
    </HeaderCustomerService>
    <HeaderLocalizeLogo />
    <HeaderActions
      @book-an-appointment="openBookAppointment"
      @open-shoppingbag="showShoppingBag()"
      @open-closest-showroom="showClosestShowroom()"
    />
    <ui-popup
      v-model="bookAppointment"
      hide-footer
      overlayClass="book-appointment"
      container-class="lg:w-[1024px] overflow-y-auto p-0"
      cy-title="appointment-form"
      @update:model-value="closeBookAppointment"
    >
      <online-appointments @close="bookAppointment = false"></online-appointments>
    </ui-popup>
    <transition name="slide">
      <HeaderShoppingBag v-if="displayShoppingBag" @close="hideShoppingBag()" />
    </transition>
    <HeaderClosestShowroom
      v-if="displayClosestShowroom"
      @book-an-appointment="openBookAppointment"
      @close-closest-showroom="hideClosestShowroom"
    />
  </section>

  <HeaderNavigation
    :display-mobile-navigation-menu="displayMobileNavigationMenu"
    @close-mobile-menu="hideMobileNavigationMenu()"
  />
</template>

<script setup lang="ts">
import CurrencySelectionPopup from './header-currency-sellection/CurrencySelectionPopup.vue'
import HeaderCustomerService from './HeaderCustomerService.vue'
import HeaderClosestShowroom from './header-closest-showroom'
import HeaderLocalizeLogo from './HeaderLocalizeLogo.vue'
import HeaderShoppingBag from './HeaderShoppingBag.vue'
import HeaderNavigation from './HeaderNavigation.vue'
import HeaderActions from './HeaderActions.vue'
import { useOnlineAppointments } from '~/store/bookAppointmentStore'
const { resetBookingAppointmentState } = useOnlineAppointments()

const displayShoppingBag = ref(false)
const displayMobileNavigationMenu = ref(false)
const displayClosestShowroom = ref(false)
const displayCurrencySelectionPopup = ref(true)
const bodyRef = ref<HTMLElement>()

const isLocked = useScrollLock(bodyRef)
const { eventBus, EVENTS } = useEventBus()

const bookAppointment = ref(false)

const openBookAppointment = () => {
  if (displayClosestShowroom.value) {
    hideClosestShowroom()
  }

  resetBookingAppointmentState()

  // TODO: implement book appointment dialog
  bookAppointment.value = true
}

// const closeBookAppointment = () => {
//   resetBookingAppointmentState()
// }

// TODO: Implement this when will be clear what to do with userPreferences
// const shouldShowPopup = computed(() => {
//   const { countryId, currencyId, hasDispatchIssues } = userPreferencesStore;
//   return hasDispatchIssues || (countryId === 'US' && (currencyId !== 'USD' || countryId !== 'US'));
// });

const showShoppingBag = () => {
  displayShoppingBag.value = true
  isLocked.value = true // lock the body scroll
  bodyRef.value.classList.add('no-scrollbar')
}

const hideShoppingBag = () => {
  displayShoppingBag.value = false
  isLocked.value = false // unlock the body scroll
  bodyRef.value.classList.remove('no-scrollbar')
}

const showMobileNavigationMenu = () => {
  displayMobileNavigationMenu.value = true
  isLocked.value = true // lock the body scroll
}
const hideMobileNavigationMenu = () => {
  displayMobileNavigationMenu.value = false
  isLocked.value = false // unlock the body scroll
}

const showClosestShowroom = () => {
  displayClosestShowroom.value = true
  isLocked.value = true // lock the body scroll
}

const hideClosestShowroom = () => {
  displayClosestShowroom.value = false
  isLocked.value = false // unlock the body scroll
}

eventBus.on(EVENTS.OPEN_HEADER_NAVIGATION_MOBILE_MENU, () => {
  showMobileNavigationMenu()
})

eventBus.on(EVENTS.OPEN_BOOK_APPOINTMENTS, (showroom) => {
  openBookAppointment()
  if (showroom) {
    useOnlineAppointments().setStep('thirdStep')
    useOnlineAppointments().selectShowroom({ showroom })
  }
})

onBeforeMount(() => {
  bodyRef.value = document.body // set the bodyRef
})
</script>

<style lang="css">
.no-scrollbar {
  padding-right: 17px;
}
.dropdown-wrapper {
  cursor: pointer;
  white-space: nowrap;

  .dropdown {
    cursor: default;
    position: absolute;
    top: 100%;
    background: white;
    margin-top: 1px;
    z-index: 9999999;
  }

  .dropdown-header {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    @apply font-domaine;
  }

  /*&:not(.-right) .dropdown {
    left: 0;
  }*/

  &.-right .dropdown {
    right: 0;
  }

  .dropdown.dropdown-scroll {
    background: #fff;
    padding: 30px;
    width: 380px;
    max-width: 100%;

    .scroll-row {
      position: relative;
      line-height: 1.2;
      margin-bottom: 5px;

      &:after {
        content: '';
        position: absolute;
        pointer-events: none;
        transform: rotate(45deg); /* Default state */
        border: solid;
        border-width: 0px 1px 1px 0px;
        height: 8px;
        width: 8px;
        right: 2px;
        bottom: 6px;
        transition: transform 0.3s ease-in-out; /* Smooth transition */
      }

      .active:after {
        transform: rotate(-135deg); /* Active state */
      }

      span {
        font-size: 14px;
        letter-spacing: 0.58px;
        display: block;
        margin-bottom: 8px;
        margin-top: 15px;
      }

      label {
        color: #3d3d3d;
        float: left;
        font-size: 11px;
        margin: 10px 0;
        text-align: left;
        width: 100%;
      }

      select {
        position: relative;
        width: 100%;
        border: 1px solid #ddd;
        box-shadow: none;
        max-width: 100%;
        height: 45px;
        font-size: 14px;
        letter-spacing: 0.58px;
        cursor: pointer;
      }
    }
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.4s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}

.slide-leave-from,
.slide-enter-to {
  transform: translateX(0%);
}
</style>
