<template>
  <ul
    class="accordion-content _list-unstyled"
    :style="{
      'max-height': getSectionMaxHeight,
    }"
  >
    <!--    TODO: add support for event handling, open popup, etc-->
    <li v-for="item in items" :key="item.title" :class="item.class">
      <template v-if="item.link">
        <nuxt-link :to="item.link" class="body-12-aa no-underline"> {{ item.title }} </nuxt-link>
      </template>
      <template v-else>
        <button type="button" class="body-12-aa p-0" @click="item.onclick()">
          {{ item.title }}
        </button>
      </template>
    </li>
  </ul>
</template>

<script setup lang="ts">
import type { FooterNavigationItem } from './types'

const props = defineProps<{
  items: FooterNavigationItem[]
}>()

const getSectionMaxHeight = computed(() => {
  return `${props.items.length * 32}px`
})
</script>
<style scoped lang="scss">
@media (max-width: 1023px) {
  .accordion-content {
    overflow: hidden;
    transition: max-height 0.4s;
  }

  li {
    line-height: 32px;
  }
}
</style>
