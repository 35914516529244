<template>
  <div data-dropdown="#showroomlist" class="headerVisitShowroom dropdown-open">
    <div class="showroom-overlay" @click="$emit('close-closest-showroom')" />

    <div id="showroomlist" class="dropdown showroomlistElm" data-static>
      <span class="closeShowroomArea" @click="$emit('close-closest-showroom')">
        <i class="icon77 icon77-nav-cross"></i>
      </span>

      <div v-if="!loading" class="showroom-list">
        <!-- @book-an-appointment="handleBookingAppointment(closestShowroom)" -->
        <ShowroomItem
          :key="closestShowroom.Id"
          :showroom="closestShowroom"
          is-closest
        />
        <!-- @book-an-appointment="handleBookingAppointment(showroom)" -->
        <ShowroomItem
          v-for="showroom in otherShowrooms"
          :key="showroom.Id"
          :showroom="showroom"
          :is-selected="showroom.Id === selectedShowroomId"
          @update:selected-showroom-id="updateSelectedShowroomId(showroom.Id)"
        />
      </div>
    </div>
    <!-- <ShowroomPopup
      v-if="displayPopup && selectedShowroomId"
      :selected-showroom="displayPopup"
      @close-showroom-popup="displayPopup = false"
    /> -->
  </div>
</template>

<script setup lang="ts">
import ShowroomItem from './ShowroomItem.vue'
const { userPreferences } = storeToRefs(useNewSettingsStore())
import { useNewSettingsStore } from '~/store/newSettingsStore'
import { useOnlineAppointments } from '~/store/bookAppointmentStore'
const { setStep, selectShowroom, getShowrooms } = useOnlineAppointments()
const { Showrooms } = storeToRefs(useOnlineAppointments())

const emit = defineEmits(['book-an-appointment'])

const loading = ref(true)
const displayPopup = ref(false)
const selectedShowroomId = ref(userPreferences.value?.ShowRoomId) // The ID of the selected showroom, manage this state according to your app's logic
const popupCount = useSessionStorage('showroom-popup-count', 0)

// const handleBookingAppointment = (showroom) => {
//   setStep('thirdStep')
//   selectShowroom(showroom)
//   emit('book-an-appointment')
// }

const closestShowroom = computed(() => Showrooms.value[0])
const otherShowrooms = computed(() => {
  return Showrooms.value.filter((showroom) => showroom.Id !== closestShowroom.value.Id)
})

const updateSelectedShowroomId = (id: string) => {
  if (id !== selectedShowroomId.value) {
    selectedShowroomId.value = id
  } else {
    selectedShowroomId.value = ''
  }
}

// TODO: confirm if the scrolling logic should be on app init or on `closest showroom` mounted
const handleScrollToShowPopup = useThrottleFn(() => {
  if (popupCount.value < 3 && !displayPopup.value) {
    displayPopup.value = true
    popupCount.value += 1
  }
}, 10000)

onMounted(async () => {
  if (!Showrooms.value) {
    await getShowrooms()
  }

  loading.value = false // TODO: add loading animation while fetching or load it on app init if it is important

  const route = useRoute()

  // TODO: confirm where it used with Eric
  // Check if the current page is 'Shop' or 'Category'
  if (route.name === 'Shop' || route.name === 'Category') {
    // Initialize popover logic here
    window.addEventListener('scroll', handleScrollToShowPopup)
  }
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScrollToShowPopup)
})
</script>

<style scoped lang="scss">
.headerVisitShowroom {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 1050;
  transition: all 0.4s;
}

.showroom-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: -1;
  cursor: pointer;
  transition: all 0.4s;
}

.dropdown {
  padding: 40px;
  padding-bottom: 0px;
  width: 500px;
  box-sizing: border-box;
  white-space: normal;
}

#showroomlist {
  box-shadow: 0 0 50px 10px rgba(0, 0, 0, 0.3);
  position: fixed;
  right: 0;
  top: -2px;
  height: 100vh;
  box-sizing: border-box;
  overflow-y: auto;
  padding: 78px 0 50px 0;
  background-color: #fff;
}

.closeShowroomArea {
  position: absolute;
  top: 24px;
  right: 36px;
  cursor: pointer;
}

@media (max-width: 1023.98px) {
  #showroomlist {
    overflow: auto;
  }
}

@media (min-width: 1024px) {
  #showroomlist {
    left: auto;
    padding: 0;
    padding: 78px 0 50px 0;
    box-shadow: none;
  }
  #showroomlist:after {
    content: '';
    position: absolute;
    width: 100vw;
    background: rgba(0, 0, 0, 0.3);
    height: 100%;
    right: 500px;
    top: -60px;
    pointer-events: none;
  }
}

@media (max-width: 1023px) {
  .headerVisitShowroom .dropdown {
    width: 100%;
    padding: 0;
  }
  .headerVisitShowroom .showroom-item {
    padding: 0 16px;
  }
}
</style>
