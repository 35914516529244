<template>
  <form novalidate class="step-3" data-cy="third-step" @submit.prevent="">
    <button type="button" class="mb-3" @click="$emit('prevStep')">
      <i class="icon77 icon77-chevron -left"></i>
      <span class="heading-5">
        {{ $t('book-an-appointment', 'Book an Appointment') }}
      </span>
    </button>

    <div
      class="contactForm scrollable-container lg:pr-7"
      data-di-form-track
      data-di-form-id="appointment-form-step-1"
      data-ga4-form="Appointment Form"
    >
      <div class="leftCol form pb-7 pr-md-3">
        <h3 class="body-14-aa my-5">
          {{ $t('date-and-time', 'DATE & TIME') }}
        </h3>
        <h4 class="body-14-aa mb-2">
          {{ $t('what-would-you-like', 'What would you like to see?') }}
        </h4>

        <div class="interest-list mb-6">
          <label v-for="i in Interests" class="block interest body-14-aa" data-cy="option">
            <input v-model="SelectedInterest" type="radio" :value="i.id" class="my-2 mr-1" />
            {{ $t(...i.label) }}
          </label>
        </div>

        <div v-show="SelectedInterest" ref="calendar" class="relative">
          <VDatePicker
            ref="vcalendar"
            v-model="SelectedDate"
            :disabled-dates="DisabledDates"
            expanded
            borderless
            first-day-of-week="1"
            class="border mar"
            @did-move="refresh"
          />
          <div v-if="DisabledDatesStatus" class="loading">
            <i class="icon77 icon77-loading"></i>
          </div>
        </div>

        <div ref="meetUs" class="meetUs" :class="{ disabled: !SelectedDate }">
          <p class="body-16-aa mt-6">
            {{ $t('when-to-meet', 'When would you like to meet us?') }}
          </p>

          <div class="meetHours meetHoursDisabled flex gap-2 my-3">
            <button type="button" class="cta" data-cy="meet-hour" @click="setPreferedTime('AM')">
              {{ $t('morning', 'Morning') }} - AM
            </button>
            <button type="button" class="cta" data-cy="meet-hour" @click="setPreferedTime('PM')">
              {{ $t('afternoon', 'Afternoon') }} - PM
            </button>
          </div>

          <p
            class="mt-3 body-14-aa tailoredText"
            v-html="
              $t(
                'tailored-consultation',
                'Your tailored consultation will last approximately one hour. Our jewellery specialist will <b>need to confirm</b> your requested date, so please ensure you have received your booking confirmation before visiting us.',
              )
            "
          ></p>
        </div>
      </div>
    </div>
  </form>
</template>

<script setup>
import { useOnlineAppointments } from '~/store/bookAppointmentStore'

const { SelectedInterest, DisabledDates, DisabledDatesStatus, SelectedDate } = storeToRefs(useOnlineAppointments())
const { Interests, getDisabledDates } = useOnlineAppointments()

const emit = defineEmits(['nextStep'])

const calendar = ref()
const vcalendar = ref()
const meetUs = ref()

const currentPage = computed(() => {
  return vcalendar.value?.calendarRef?.pages[0]
})

// const interest = ref(props.selectedInterest || null)
watch(SelectedInterest, () => refresh())
watch(DisabledDatesStatus, (value) => {
  if (!value) {
    calendar.value.scrollIntoView({ behavior: 'smooth' })
  }
})

watch(SelectedDate, (value) => {
  if (value) {
    meetUs.value.scrollIntoView({ behavior: 'smooth' })
  }
})

const refresh = () => {
  getDisabledDates(currentPage.value)
}

const setPreferedTime = (preferedTime) => {
  // preferedTime = time;
  emit('nextStep', { preferedTime })
}

onMounted(async () => {
  if (SelectedInterest.value) refresh()
})
</script>

<style lang="scss">
.step-3 {
  .contactForm.-scrollable {
    @media screen and (min-width: 768px) {
      position: absolute;
      height: 90%;
      width: 100%;
      overflow: auto;
    }
  }

  .interest-list {
    .interest {
      cursor: pointer;
    }

    input {
      width: 15px;
      height: 15px;
      vertical-align: -2px;
      accent-color: #232323;
    }
  }

  .loading {
    position: absolute;
    width: 100%;
    background: #ffffff9e;
    height: 100%;
    top: 0;
    z-index: 1;

    .icon77 {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -20px;
      z-index: 1;
    }
  }

  .vc-container {
    .vc-header {
      margin: 20px 10px;
    }

    .vc-title-wrapper {
      font-family: 'Plain Light';
      font-size: 14px;
    }

    .vc-base-icon {
      stroke: #000;
      stroke-width: 1px;
    }
  }

  .meetUs {
    &.disabled {
      pointer-events: none;
      opacity: 0;
    }
  }
}
</style>
<!--<script>
    // import { mapState, mapActions, mapGetters} from 'vuex'
    // import { required, minLength, between, email, requiredIf } from 'vuelidate/lib/validators'

    import axios from "axios";
    import { ref, nextTick } from 'vue'

    export default {
        props: ['selectedInterest', 'currentShowroom', 'selectedDate'],

        data() {
            return {
                interest: this.selectedInterest ? [this.selectedInterest] : [],
                loading: true,
                currentDate: null,
            }
        },

        watch: {
            async interest(newValue, currentvalue) {
                if (newValue.length > 1)
                    this.interest = [newValue.pop()];

                await nextTick();

                $("#dateInput").get(0).scrollIntoView({behavior: 'smooth'});
            },
        },

        validations () {
            // let isDeliveryAddress = requiredIf(() => this.user.DeliveryOption == 'DeliveryAddress')

            return {
            };
        },

        computed: {
        },

        methods: {
            async loadCalendar(selectFirstAvailable, date) {
                // await nextTick();

                let _calendar = $("#_77calendar");

                _calendar.empty();

                // Create the calendar
                var calendar = _77Calendar.new(_calendar[0], date);

                // calendar.onVisibleRender(function (event, visible) {
                //     debugger;
                // });

                calendar.onMonthChange((event, month) => {
                    this.updateCalendarAvailability(month);
                });

                calendar.onDateClick(async (event, date) => {
                    this.currentDate = date;

                    await nextTick();

                    $(".meetUs").get(0).scrollIntoView({behavior: 'smooth'});
                });

                this.updateCalendarAvailability(calendar._date, selectFirstAvailable);
            },

            updateCalendarAvailability (month, selectFirstAvailable) {
                let endDay = new Date(month);
                endDay.setMonth(endDay.getMonth() + 1);
                endDay.setDate(endDay.getDate() - 1);

                this.loading = true;

                axios
                    .post(`/WebService.asmx/GetCalendarAvailability`, {
                        collectionPlaceId: this.currentShowroom.Id,
                        endDay,
                        idProductType: 24,
                        startDay: month,
                    })
                    .then(async (response) => {
                        if (response) {
                            var list = response.data.d;

                            if (list.length > 0 && list) {
                                for (var j = 0 ; j < list.length; j++) {

                                    if (!list[j].IsAvailable) {
                                        $("#_77calendar td").filter((i, a) => a.textContent == (j + 1)).addClass('calendar77-disabled');
                                    } else {
                                        // $("#_77calendar td").filter((i, a) => a.textContent == (j + 1)).removeClass('calendar77-disabled');
                                    }
                                }

                                if (selectFirstAvailable) {
                                    let firstAvailableDate = list.findIndex(function (sh) { return sh.IsAvailable });
                                    this.setCalendarDay(firstAvailableDate + 1);

                                    // $('#_77calendar td').filter((i, a) => a.textContent == firstAvailableDate + 1).addClass('calendar77-active');

                                } else if (this.selectedDate && month.getMonth() == this.selectedDate.getMonth()) {
                                    this.setCalendarDay(this.selectedDate.getDate());
                                }
                            }
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            },

            async setCalendarDay(day, forceClick) {
                let calenderItem = $("#_77calendar td:not(.calendar77-next)").filter((i, a) => a.textContent == day);

                if (forceClick)
                    calenderItem.click();
                else
                    calenderItem.addClass('calendar77-active');
            },

            setPreferedTime(preferedTime) {
                this.preferedTime = preferedTime;
                this.$emit('nextStep', {
                    selectedInterest: this.interest[0],
                    selectedDate: this.currentDate,
                    preferedTime
                })
            },

            submit() {
                this.$emit('nextStep');
            }
        },

        mounted() {
            if (!this.selectedDate)
                this.loadCalendar(true);
            else {
                this.currentDate = this.selectedDate;
                this.loadCalendar(false, new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 1, 0, 0, 0));
            }
        },
    }
</script>-->
